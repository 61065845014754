const checkStrVar = (variable: string | undefined, name: string): string => {
  if (!variable) throw new Error(`undefined variable: ${name}`);
  return variable;
};

const BACKEND_URL = checkStrVar(
  process.env.REACT_APP_BACKEND_URL,
  'REACT_APP_BACKEND_URL',
);
const BACKEND_URL_BASE = checkStrVar(
  process.env.REACT_APP_BACKEND_URL_BASE,
  'REACT_APP_BACKEND_URL_BASE',
);

const DEMO_URL = checkStrVar(
  process.env.REACT_APP_DEMO_URL,
  'REACT_APP_DEMO_URL',
);

const PATHS = {
  LOGIN: '/login',
  CREDENTIAL_ORDER: '/credential-order',
  CREDENTIAL_PROJECTIONS: '/credential-projections',
  CREDENTIAL: '/credential',
  USER_CREDENTIAL: '/user/credential',
  CREDENTIAL_TYPES: '/credential-types',
  ISSUE_CREDENTIAL: '/ivm-auth',
  IDENTITY_VERIFICATION_METHOD: '/ivm',
  USER_CREDENTIALS: '/user/credentials',
  USER_CREATE_CREDENTIAL: '/user/create-credential',
  ENTITY_LIST: '/entities',
  TOKEN: '/token',
  RESEND_EMAIL: '/credential/resend-email',
  USER_RESEND_EMAIL: '/user/credential/resend-email',
  REVOKE_CREDENTIAL: '/revocation',
  USER_REVOKE_CREDENTIAL: '/user/revocation',
  AUDIT_TRAILS: '/audit-trails',
  CREDENTIAL_REQUESTS: '/credential-requests',
  DEVICE_LIST: '/sign-device-list',
  DOCUMENT_STATUS: '/sign-document-status',
};

const OPENID = {
  CLIENT_ID: process.env.REACT_APP_OPENID_CLIENT_ID,
  IDENTITY_PROVIDER: process.env.REACT_APP_IDENTITY_PROVIDER,
  REDIRECT_CALLBACK: process.env.REACT_APP_REDIRECT_CALLBACK,
  REDIRECT_CALLBACK_LOGIN: process.env.REACT_APP_REDIRECT_CALLBACK_LOGIN,
};

export { BACKEND_URL, PATHS, OPENID, BACKEND_URL_BASE, DEMO_URL };
