import {
  getUserCredential,
  ParsedBackendError,
  resendEmailUserCredentialOrder,
  revokeUserVerifiableCredential,
} from '../../../services/vidcredentials';
import React, { useContext, useState } from 'react';
import {
  CredentialDetailsView,
  CredentialListView,
  UserCredentialListView,
} from '../../../domain/credentialView';
import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.png';
import revokeIcon from '../../../assets/revokeIcon.png';
import resendIcon from '../../../assets/resendIcon.png';
import TooltipItem from './../../../components/ToolTipItem/ToolTipItem';
import MaterialTable from 'material-table';
import { Popup } from '../../../components/Popup/Popup';
import { AuthContext } from '../../../contexts/AuthContext';
import { CredentialDetailsModal } from '../../Credentials/CredentialDetailsModal/CredentialDetailsModal';
import CustomPagination from '../../../components/TablePagination/CustomPagination';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { UserCredentialsContext } from '../../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

export function UserCredentialsTable() {
  const { t } = useTranslation();
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);

  const [projection, setProjection] = useState({} as CredentialListView);
  const [credential, setCredential] = useState({} as CredentialDetailsView);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [revocationPath, setRevocationPath] = useState<string | null>(null);
  const { tableSpecs, loadCredentials, credentials, isLoading, filters } =
    useContext(UserCredentialsContext);

  const { entityName, ...filtersWithoutEntityName } = filters;
  const { isSuperAdmin } = useContext(AuthContext);

  const openCredential = async (data: UserCredentialListView, e: any) => {
    e.preventDefault(); //Prevent the default href=# behavoir
    try {
      const credential = await getUserCredential(data.detailsURL);
      setCredential(credential as CredentialDetailsView);
      setProjection(data);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
      return;
    }
  };
  const revokeCredential = async (revocationPath: string) => {
    try {
      await revokeUserVerifiableCredential(revocationPath);
      setTimeout(
        () => loadCredentials(tableSpecs.tableSize, tableSpecs.currentPage),
        5000,
      );
      setSuccessMessage(t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
    setRevocationPath(null);
  };

  const resendEmail = async (resendEmailPath: string, e: any) => {
    try {
      e.preventDefault();
      await resendEmailUserCredentialOrder(resendEmailPath);
      setSuccessMessage(t('details.emailResentSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  const tableColumns = [
    {
      title: 'Email',
      field: 'email',
    },

    {
      title: 'DID',
      field: 'did',
      render: (data: UserCredentialListView) => {
        return (
          <>
            {data.status === 'ISSUED' ? (
              <TooltipItem id={'did' + data.issuanceId} title={data.did}>
                <div className="divFixed">
                  <span>{data.did}</span>
                </div>
              </TooltipItem>
            ) : (
              t('table.notAvailable')
            )}
          </>
        );
      },
    },
    {
      title: t('table.creationDate'),
      field: 'createdAt',
      render: (data: UserCredentialListView) => formatDate(data.creationDate),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: UserCredentialListView) => t(`status.${data.status}`),
    },
    {
      title: t('details.role'),
      field: 'role',
      render: (data: UserCredentialListView) => data.userRole,
    },
    {
      title: 'Entity',
      field: 'entity',
      hidden: !isSuperAdmin,
      render: (data: UserCredentialListView) => data.userEntityName,
    },
    {
      title: '',
      field: '',
      render: (data: UserCredentialListView) => {
        return (
          <div className="icons-container">
            <button
              onClick={() => setRevocationPath(data.revokeURL || null)}
              disabled={!data.revokeURL}
              className="imageButton"
            >
              <TooltipItem
                id={'revoke' + data.issuanceId}
                title={t('table.revokeCredential')}
                enabled={data.status === 'ISSUED'}
              >
                <img src={revokeIcon} />
              </TooltipItem>
            </button>{' '}
            <button // TODO: use the urls to check if the buttons should be enabled
              disabled={data.status !== 'VERIFIED'}
              className="imageButton"
              onClick={(e) => resendEmail(data.resendURL as string, e)}
            >
              <TooltipItem
                id={'resend' + data.issuanceId}
                title={t('table.resendEmail')}
              >
                <img src={resendIcon} />
              </TooltipItem>
            </button>
            <button
              className="imageButton"
              onClick={(e) => openCredential(data, e)}
            >
              <TooltipItem
                id={'detail' + data.issuanceId}
                title={t('table.viewDetails')}
              >
                <img src={eyeSelectedIcon} className="eyeIcon" />
              </TooltipItem>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CredentialDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
        projection={projection}
      />
      <MaterialTable
        key={tableSpecs.tableSize}
        columns={tableColumns}
        data={credentials}
        localization={{
          body: {
            emptyDataSourceMessage: t('table.empty'),
          },
        }}
        isLoading={isLoading}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: tableSpecs.tableSize,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            fontFamily: 'TT-Norms-Black',
          },
          maxBodyHeight: screen.height * 0.6,
          emptyRowsWhenPaging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ),
          Pagination: () => (
            <CustomPagination
              loadCredentials={loadCredentials}
              tableSpecs={tableSpecs}
              filters={filtersWithoutEntityName}
            />
          ),
        }}
      />
      {errorMessage && (
        <Popup
          error
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {successMessage && (
        <Popup
          success
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
      )}
      {revocationPath && (
        <Popup
          confirmation
          message={t('table.sureRevoke')}
          onConfirm={() => revokeCredential(revocationPath)}
          onReject={() => setRevocationPath(null)}
          onClose={() => setRevocationPath(null)}
        />
      )}
    </div>
  );
}
