import React, { useContext, useState } from 'react';
import { Button, Container } from 'reactstrap';
import './UserCredentials.css';
import { MenuContext } from '../../contexts/MenuContext';
import { UserCredentialsTable } from './UserCredentialsTable/UserCredentialsTable';
import { IssueUserCredentialForm } from '../../components/IssueUserCredentialForm/IssueUserCredentialForm';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import refreshIcon from '../../assets/refreshIcon.svg';
import {
  UserCredentialsContext,
  UserCredentialsFilters,
} from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import FilterTag from '../../components/FilterTag/FilterTag';
import { convertToQueryString } from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';

export function UserCredentials() {
  const { t } = useTranslation();
  const TAGS_NAMES: Record<string, any> = {
    entityId: t('details.entity'),
    entityName: t('details.entity'),
    email: 'Email',
    did: 'DID',
    searchableText: t('filters.searchText'),
    status: t('table.status'),
  };
  const { filters, setFilters, tableSpecs, loadCredentials } = useContext(
    UserCredentialsContext,
  );
  const { isMenuOpen } = useContext(MenuContext);
  const [isIssueUserCredentialFormOpen, setIssueUserCredentialFormOpen] =
    useState(false);

  useRouteSecurization(['admin', 'superadmin']);

  const refreshData = async () => {
    const { entityName, ...filtersWithoutEntityName } = filters;
    const queryParams = convertToQueryString(filtersWithoutEntityName);
    await loadCredentials(
      tableSpecs.tableSize,
      tableSpecs.currentPage,
      queryParams,
    );
  };

  const onRemoveTag = async (filterKey: keyof UserCredentialsFilters) => {
    const updatedFilter = {
      ...filters,
      [filterKey]: '',
      entityId: filterKey === 'entityName' ? '' : filters.entityId,
    };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <IssueUserCredentialForm
        isIssueUserCredentialOpen={isIssueUserCredentialFormOpen}
        setIsIssueUserCredentialOpen={setIssueUserCredentialFormOpen}
        loadTable={loadCredentials}
        currentPage={tableSpecs.currentPage}
        tableSize={tableSpecs.tableSize}
        queryParams={convertToQueryString(filters)}
      />
      <h1>{t('menu.user')}</h1>
      <br></br>
      <SearchBar context={UserCredentialsContext} />
      <div className="filter-list-container">
        {Object.entries(filters).map(([key, value]) => {
          if (key === 'entityId') return null;
          return (
            value && (
              <FilterTag
                filter={`${TAGS_NAMES[key]}: ${value}`}
                onClose={() => onRemoveTag(key as keyof UserCredentialsFilters)}
              />
            )
          );
        })}
      </div>
      <Button
        className="buttonCreateUser"
        onClick={() => setIssueUserCredentialFormOpen(true)}
      >
        {t('actions.create')}
      </Button>
      <div className="refresh-icon-container">
        <img
          onClick={refreshData}
          src={refreshIcon}
          alt="Refresh icon"
          className="refresh-button"
        />
      </div>
      <UserCredentialsTable />
    </Container>
  );
}
